import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Puff } from "react-loader-spinner";
import Pagination from "UpdatedComponents/Pagination";
import {
  createSeller,
  enableDisableClient,
  getPointOfSales,
  getSellers,
  updateSeller,
} from "UpdatedFeatures/api";
import AddVendeurModal from "./AddVendeurModal";
import { toast } from "react-toastify";
import { FaLock, FaLockOpen, FaPen, FaTrash } from "react-icons/fa";
import EditSeller from "./EditSeller";
import { IoSearch } from "react-icons/io5";

export default function VendeursTable() {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [seller, setSeller] = useState(false);
  const [loadingSellerId, setLoadingSellerId] = useState(null);
  const [search, setSearch] = useState(""); // State for search input

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
    gender: "",
    birth: "",
    role: null,
    extra: [],
    point_of_sale_id: null,
  });
  const [editFormData, setEditFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    birth: "",
    role: null,
    extra: [],
    point_of_sale_id: null,
  });

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [posPages, setPosPages] = useState(1000);
  const queryClient = useQueryClient();

  const {
    data: sellers,
    error,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["sellers", page, perPage, search],
    queryFn: () => getSellers(page, perPage, search),
    keepPreviousData: true, // Keeps the previous data until the new data arrives
  });
  console.log(sellers)
  const totalPages = Math.ceil(sellers?.total / perPage);
  const { data: pos } = useQuery({
    queryKey: ["pointOfSales", page, posPages],
    queryFn: () => getPointOfSales(page, posPages),
    keepPreviousData: true, // Keep previous data while fetching new page
  });

  const posData = pos?.data;

  const createMutation = useMutation({
    mutationFn: ({ data }) => createSeller(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sellers", page, perPage, search]);
      toast.success("L'utilisateur a été ajouté !");
      setOpen(false);
    },
    onError: (error) => {
      console.log(error.response.data.error)
      toast.error(`Erreur lors de l'ajout du l'utilisateur : ${error.response.data.error}`);
      setOpen(false);
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ seller, data }) => updateSeller(seller, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["sellers", page, perPage, search]);
      setOpenEdit(false);
      toast.success("L'utilisateur a été modifié !");
    },
    onError: (error) => {
      console.log(error)
      toast.error(
        `Erreur lors de la modification du l'utilisateur : ${error.response.data.error}`
      );
      setOpenEdit(false);
    },
  });

  const toggleMutation = useMutation({
    mutationFn: ({ user }) => enableDisableClient(user),
    onMutate: (variables) => {
      setLoadingSellerId(variables.user); // Set loading state for the specific seller
    },
    onSuccess: () => {
      toast.success("Le Status du l'utilisateur a été modifié !");
      queryClient.invalidateQueries(["sellers", page, perPage, search]); // Invalidate queries to refresh the data
      setLoadingSellerId(null); // Reset loading state
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de la modification du Status du l'utilisateur : ${error.response.data.error}`
      );
      setLoadingSellerId(null); // Reset loading state
    },
  });

  const nextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleEditClick = (seller) => {
    setSeller(seller);
    setOpenEdit(true);
  };

  const handleToggleClick = (sellerId) => {
    toggleMutation.mutate({
      user: sellerId,
    });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page when search input changes
  };

  if (isLoading && !isFetching) {
    return (
      <div className="flex justify-center mt-20 mr-12">
        <Puff
          visible={true}
          height="100"
          width="100"
          color="#895a7c"
          ariaLabel="puff-loading"
        />
      </div>
    );
  }

  if (isError) return <div>Error: {error.message}</div>;

  const handleSelect = (email) => {
    setSelectedUsers((prev) => {
      if (prev.includes(email)) {
        return prev.filter((selectedEmail) => selectedEmail !== email);
      } else {
        return [...prev, email];
      }
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-12">
      <AddVendeurModal
        formData={formData}
        setFormData={setFormData}
        mutation={createMutation}
        open={open}
        setOpen={setOpen}
        pos={posData}
      />
      <EditSeller
        setOpen={setOpenEdit}
        open={openEdit}
        setFormData={setEditFormData}
        formData={editFormData}
        mutation={editMutation}
        seller={seller}
        pos={posData}
      />
      <div className="sm:flex sm:items-center justify-between">
        <div className="relative w-1/3">
          <form className="flex items-center">
            <input
              type="text"
              placeholder="Rechercher..."
              className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
              value={search} // Bind search state to input value
              onChange={handleSearchChange} // Update search state on input change
            />
            <button
              type="submit"
              className="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <IoSearch color="purple" />
            </button>
          </form>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="flex items-center justify-center px-3 py-1 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
            onClick={() => setOpen(true)}
          >
            <span className="text-sm">+ Ajouter</span>
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-[#b269ae]">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6"
                    >
                      <input
                        type="checkbox"
                        className="rounded-sm"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers(
                              sellers?.data.map((seller) => seller.email)
                            );
                          } else {
                            setSelectedUsers([]);
                          }
                        }}
                        checked={
                          selectedUsers.length === sellers?.data.length &&
                          sellers?.data.length > 0
                        }
                      />
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6"
                    ></th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      E-mail
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Point of Sale
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Modifier</span>
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div className="flex justify-center w-full mx-auto">
                    <Puff
                      visible={true}
                      height="50"
                      width="50"
                      color="#895a7c"
                      ariaLabel="puff-loading"
                    />
                  </div>
                ) : (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {sellers?.data.map((seller) => (
                      <tr key={seller.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <input
                            type="checkbox"
                            className="rounded-sm"
                            checked={selectedUsers.includes(seller.email)}
                            onChange={() => handleSelect(seller.email)}
                          />
                        </td>
                        <td className="whitespace-nowrap py-4  text-sm font-medium text-gray-900 ">
                          {seller.photo ? (
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPI_5Oh0Hm4GqINS6t1U_DJhMbbFx0gzWwNA&s"
                              alt={seller.first_name}
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          ) : (
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPI_5Oh0Hm4GqINS6t1U_DJhMbbFx0gzWwNA&s"
                              alt={seller.first_name}
                              className="w-12 h-12 rounded-full object-cover"
                            />
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {seller.first_name} {seller.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {seller?.roles?.name || "Vendeur"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {seller.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {seller.point_of_sale?.name || "Non spécifié"}
                        </td>
                        <td className="relative flex items-center justify-end whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div className="flex space-x-4 mt-3">
                            {seller.is_enable ? (
                              <button
                                className="text-[#b269ae] hover:text-purple-300"
                                onClick={() => handleToggleClick(seller.id)}
                                disabled={loadingSellerId === seller.id} // Disable button if this seller is being toggled
                              >
                                {loadingSellerId === seller.id ? (
                                  "loading"
                                ) : (
                                  <FaLockOpen size={18} />
                                )}
                              </button>
                            ) : (
                              <button
                                className="text-[#b269ae] hover:text-purple-300"
                                onClick={() => handleToggleClick(seller.id)}
                                disabled={loadingSellerId === seller.id} // Disable button if this seller is being toggled
                              >
                                {loadingSellerId === seller.id ? (
                                  "loading"
                                ) : (
                                  <FaLock size={18} />
                                )}
                              </button>
                            )}

                            <button
                              className="text-[#b269ae] hover:text-purple-300"
                              onClick={() => handleEditClick(seller)}
                            >
                              <FaPen size={18} />
                            </button>
                            <button className="text-[#b269ae] hover:text-purple-300">
                              <FaTrash size={18} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        nextPage={nextPage}
        prevPage={prevPage}
        setPage={setPage} // Pass the setPage function
      />
    </div>
  );
}
