import { RouteIdEnum } from 'config/enums'
import DashboardLayout from 'layouts/layouts/DashboardLayout/DashboardLayout'

import React from 'react'
import RolesTable from './RolesTable'

const Roles = () => {
    return (
        <DashboardLayout
            title={"Gestion des Roles"}
            selectedPath={RouteIdEnum.Roles}
        >
            <RolesTable />
        </DashboardLayout>
    )
}

export default Roles