import WebRounded from "../assets/img/webRounded.svg";
import LoyaltyRounded from "../assets/img/loyaltyRounded.svg";
import MenuBook from "../assets/img/menuBook.svg";
import PeopleAlt from "../assets/img/peopleAlt.svg";
import slider from "../assets/img/slider.svg";
import { MenuInterface } from "components/Dashboard/Menu/Menu.type";
import { RouteIdEnum } from "config/enums";
import Tax from "../assets/img/tax.png"
import Delivery from "../assets/img/delivery.png"
import stockPlus from "../assets/img/stockPlus.png"
import Provider from "../assets/img/provider.png"
import Category from "../assets/img/category.svg"
import Brand from "../assets/img/brand.png"
import Statistics from "../assets/img/statistics.png"
import Chat from "../assets/img/chat.png"
import Dashboard from "../assets/img/dashboard.png"
import Promotion from "../assets/img/promotion.png"
import pos from "../assets/img/pos.png";
import seller from "../assets/img/seller.png";
import roles from "../assets/img/roles.png";
export const menu: MenuInterface[] = [
  {
    title: "menu.dashboard",
    path: RouteIdEnum.Dashboard,
    icon: Dashboard,
    children: [],
  },
  {
    title: "menu.clients",
    path: RouteIdEnum.Clients,
    icon: PeopleAlt,
    children: [],
  },
  {
    title: "menu.categories",
    path: RouteIdEnum.Categories,
    icon: MenuBook,
    children: [],
  },
  {
    title: "menu.products",
    path: RouteIdEnum.Products,
    icon: slider,
    children: [],
  },
  {
    title: "menu.roles",
    path: RouteIdEnum.Roles,
    icon: roles,
    children: [],
  },
  {
    title: "POS",
    path: RouteIdEnum.POS,
    icon: pos,
    children: [],
  },
  {
    title: "Utilisateurs",
    path: RouteIdEnum.Vendeurs,
    icon: seller,
    children: [],
  },
  {
    title: "menu.stock_plus",
    path: RouteIdEnum.stockPlus,
    icon: stockPlus,
    children: [],
  },
  {
    title: "menu.promotion_codes",
    path: RouteIdEnum.PromotionCodes,
    icon: LoyaltyRounded,
    children: [],
  },
  {
    title: "menu.order_history",
    path: RouteIdEnum.OrderHistory,
    icon: WebRounded,
    children: [],
  },
  {
    title: "menu.supplier",
    path: RouteIdEnum.Supplier,
    icon: Provider,
    children: [],
  },
  {
    title: "menu.delivery",
    path: RouteIdEnum.Delivery,
    icon: Delivery,
    children: [
      {
        title: "menu.cat_exped",
        path: RouteIdEnum.Expedition_Categories,
        icon: Category,
        children: [],
      },
      {
        title: "menu.mode_exped",
        path: RouteIdEnum.Expedition_Mode,
        icon: Delivery,
        children: [],
      },
    ],
  },
  {
    title: "menu.tax",
    path: RouteIdEnum.Tax,
    icon: Tax,
    children: [],
  },
  {
    title: "menu.promo_flash",
    path: RouteIdEnum.Promo_Flash,
    icon: Promotion,
    children: [],
  },
  {
    title: "menu.brands",
    path: RouteIdEnum.Brand,
    icon: Brand,
    children: [],
  },
  {
    title: "Opening Hours",
    path: RouteIdEnum.Opening_Hours,
    icon: Statistics,
    children: [],
  },
];
