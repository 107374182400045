export interface MenuPropsInterface {
  handleDrawerClose: () => void; // handle close drawer
  menuPath: string; //selected menu path
}

export type SectionPropsInterface = {
  path?: string;
  title: string;
  icon: any;
  children?:SectionPropsInterface[]|[]
};

export type ListeItemPropsInterface = {
  isselected: boolean;
};

export interface MenuInterface {
  id?: number;
  title: string;
  path: string;
  icon: any;
  children?:MenuInterface[]
}

export const DRAWER_WIDTH_MAX = 295;
export const DRAWER_WIDTH_MIN = 55;
