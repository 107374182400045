import { Fragment, useCallback, useEffect, useState } from "react";
import {
  ContainerStyle,
  ErrorStyle,
  FragmentStyle,
  OptionStyle,
  LabelStyle,
} from "./SelectInput.style";
import { SelectInputsPropsShowInterface } from "./SelectInputShow.type";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function SelectInputShow({
  required,
  value, // selected item
  error, // validation error msg
  onChange, // on change item
  label, // select label
  data, // items
  onBlur, // handle on blur
  none = false, // show empty option in select input
  disabel = false, // disable select
  firstInput = false,
}: SelectInputsPropsShowInterface) {
  // translation hooks
  const { t } = useTranslation();
  // check if select is open or not
  const [open, setOpen] = useState<boolean>(false);

  // on blur add text to label
  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setOpen(false);
      if (onChange !== undefined) {
        onChange(event.target.value as string);
      }
    },
    [setOpen, onChange]
  );

  // on blur remove text from label
  const handleFocus = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <>
      <FormControl
        fullWidth
        sx={{ zIndex: 999999 }} // Set z-index here
      >
        <InputLabel required={required}>{label}</InputLabel>
        <Select
          sx={{
            backgroundColor: 'white',
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#B269AE!important',
              },
              '&:hover': {
                borderColor: 'white',
              },
              '&.Mui-focused': {
                borderColor: '#B269AE!important',
                boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
              },
            },
          }}
          value={value || []}
          label={label}
          onChange={handleChange}
          onFocus={handleFocus} // Ensure the select opens on focus
          multiple
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 999999, // Optionally, set the z-index for the dropdown menu
              },
            },
          }}
        >
          {data.map((e) => (
            <MenuItem key={e.id} value={e.id} style={{ zIndex: 999999 }} >
              {!open && `${e.id}` === `${value}`}
              {t(e.name)}
            </MenuItem>
          ))}
        </Select>
        {error !== "" && <ErrorStyle>{t(error)}</ErrorStyle>}
      </FormControl>
    </>
  );
}

export default SelectInputShow;
