import SelectInputShow from "components/common/SelectInput/SelectInputShow";
import { AutoInputsContainerStyle } from "features/Product/ProductAdd/Product.style";
import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import MultiSelectDropdown from "./MultiSelect";
import { getRoles } from "UpdatedFeatures/api";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

// Assuming you have an API function for adding a vendeur

const AddVendeurModal = ({
  open,
  setOpen,
  setFormData,
  formData,
  mutation,
  pos
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [erreur, setErreur] = useState(null);
  console.log(selectedRole)
  useEffect(() => {
    setFormData({ ...formData, extra: selectedOptions });
  }, [selectedOptions])

  const {
    data: roles,
    error,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: () => getRoles(),
  });
  console.log(formData)
  if (!open) return null;
  const token = localStorage.getItem("token");

  const extraOptions = [
    "clients",
    "promotion_codes",
    "categories",
    "products",
    "order_history",
    "supplier",
    "delivery",
    "cat_exped",
    "mode_exped",
    "tax",
    "promo_flash",
    "stock_plus",
    "dashboard",
    "roles",
    "advanced_stat",
    "stat_products",
    "stat_shipping_mode",
    "brands",
    "stats_visits",
    "pos",
    "sellers"
  ]
  // Handle changes in input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    if (value === "t") {
      setFormData({ ...formData, [name]: null });
      setSelectedRole(value)
    }
    else {
      setSelectedRole(value)
      setFormData({ ...formData, [name]: value });
    }
  };

  console.log(formData)
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.gender) {
      alert("Please select a gender");
      return;
    }
    if (formData.password != formData.password_confirmation) {
      setErreur("Les champs de mot de passe ne sont pas identiques")
      return;
    }
    const dataToSend = {
      ...formData,
    };

    mutation.mutate({ data: dataToSend, token });
  };

  return (
    <div
      className="fixed inset-0 z-50"
      style={{ zIndex: 99999, overflow: 'visible' }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75"
        aria-hidden="true"
        onClick={() => setOpen(false)} // Close modal when clicking the overlay
      />

      {/* Modal Content */}
      <div className="flex items-center justify-center min-h-screen p-4">
        <div
          className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
          onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
        >
          {/* Header */}
          <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30">
            <h3
              id="modal-title"
              className="text-lg font-medium leading-6 text-white"
            >
              Ajouter un Utilisateur
            </h3>
            <button
              type="button"
              className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
              onClick={() => setOpen(false)}
            >
              <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 p-6">
            <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-4">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prénom
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Numéro de téléphone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mot de passe
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="password_confirmation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirmation du mot de passe
                </label>
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Genre
                </label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="" disabled>
                    Sélectionner le genre
                  </option>
                  <option value="male">Homme</option>
                  <option value="female">Femme</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="birth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date de naissance
                </label>
                <input
                  id="birth"
                  name="birth"
                  type="date"
                  value={formData.birth}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700"
                >
                  Role
                </label>
                <select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value={null} disabled={formData.role ? true : false}>
                    Sélectionner un Role
                  </option>
                  <option value="t">
                    Vendeur
                  </option>
                  {roles.data.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedRole === "t" && <div>
                <label
                  htmlFor="point_of_sale_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Point de vente
                </label>
                <select
                  id="point_of_sale_id"
                  name="point_of_sale_id"
                  value={formData.point_of_sale_id}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="" disabled={formData.point_of_sale_id ? true : false}>
                    Sélectionner un point de vente
                  </option>
                  {pos.map((pointOfSale) => (
                    <option key={pointOfSale.id} value={pointOfSale.id}>
                      {pointOfSale.name}
                    </option>
                  ))}
                </select>
              </div>}
              <MultiSelectDropdown options={extraOptions} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} />
            </div>
            {erreur ? <span className="text-red-600 my-2">{erreur}</span> : null}
            <div className="mt-6 flex justify-end gap-6">
              <button
                type="submit"
                className="block rounded-md bg-[#b269ae] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVendeurModal;
