import { RouteIdEnum } from "config/enums";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "utils/services/storage.service";

interface PrivateRouteInterface {
  element: JSX.Element;
  requiredRoles: string;
}

const PrivateRoute = ({ element, requiredRoles }: PrivateRouteInterface) => {

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  console.log(userData);

  const userPermissions = userData?.roles?.functionality || []; // Optional chaining for safety
  const extraRoles = userData?.extras || [];
  const combinedPermissions = [...userPermissions, ...extraRoles];
  console.log(combinedPermissions);

  const isSuperAdmin = userData.roles ? false : true;
  const hasRequiredRole = isSuperAdmin || combinedPermissions.includes(requiredRoles); // Allow access if super admin or has required role
  console.log(hasRequiredRole);

  // Check if the user is logged in and has the required role
  return isLoggedIn() && hasRequiredRole ? (
    element
  ) : (
    <Navigate to={!hasRequiredRole ? `/${RouteIdEnum.Any}` : `/${RouteIdEnum.Login}`} replace />
  );
};

export default PrivateRoute;
