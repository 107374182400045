import apiClient from "./apiClient";


// Function to create a new Point of Sale
export const createPointOfSale = async (data) => {
    try {
        const response = await apiClient.post('/point-of-sale/create', data);
        return response.data;
    } catch (error) {
        console.error('Error creating Point of Sale:', error);
        throw error;
    }
};

export const getPointOfSales = async (page, perPage, search) => {
    try {
        const response = await apiClient.get('/point-of-sale', {
            params: {
                page,
                perPage,
                search
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error Getting Point of Sale:', error);
        throw error;
    }
};

export const getAllPointOfSales = async () => {
    try {
        const response = await apiClient.get('/basket/get-points-of-sale');
        console.log('api response', response);
        return response.data;
    } catch (error) {
        console.error('Error Getting Point of Sale:', error);
        throw error;
    }
};

export const updatePointOfSale = async (id, data) => {
    try {
        const response = await apiClient.put(`/point-of-sale/${id}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating Point of Sale:', error);
        throw error;
    }
};

export const getSellers = async (page, perPage, search) => {
    try {
        const response = await apiClient.get('/sellers', {
            params: {
                page,
                perPage,
                search
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error Getting Sellers List:', error);
        throw error;
    }
};

export const createSeller = async (data) => {
    try {
        const response = await apiClient.post('/sellers/create', data);
        return response.data;
    } catch (error) {
        console.error('Error creating Seller:', error);
        throw error;
    }
};

export const updateSeller = async (seller, data) => {
    console.log(data)
    try {
        const response = await apiClient.put(`/sellers/update/${seller}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating Seller:', error);
        throw error;
    }
};

export const enableDisableClient = async (user) => {
    try {
        const response = await apiClient.post(`/clients/enable-disable/${user}`, {}); // Empty body if not required
        return response.data;
    } catch (error) {
        console.error('Error disabling or enabling Client:', error);
        throw error;
    }
};

export const getOrderHistories = async (page, perPage, search, type, status) => {
    try {
        const response = await apiClient.get('/order-histories', {
            params: {
                page,
                perPage,
                search,
                type,
                status,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching order histories:', error);
        throw error;
    }
};

export const generateLoginToken = async (clientId) => {
    try {
        const response = await apiClient.get(`/clients/generate-login-token/${clientId}`);
        return response.data;
    } catch (error) {
        console.error('Error generating login token:', error);
        throw error;
    }
};
// Function to change the status of an order
export const changeOrderStatus = async (order_id, status) => {
    try {
        const response = await apiClient.post('/order-histories/change-status', {
            order_id,
            status
        });
        return response.data;
    } catch (error) {
        console.error('Error changing order status:', error);
        throw error;
    }
};
// Function to change the status of an order
export const getFormatUnit = async () => {
    try {
        const response = await apiClient.get('/Format-type');
        return response.data;
    } catch (error) {
        console.error('Error getting format unit:', error);
        throw error;
    }
};
export const addFormatUnit = async (name) => {
    try {
        const response = await apiClient.post('/Format-type', {
            name
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de ajout', error);
        throw error;
    }
};

export const getProducts = async (search, Status, garantie, category, subCategory_id, date_limite_de_consommation, selectedPOS, selectedStatus, selectedItems, selectAll) => {

    try {
        const response = await apiClient.get('/products', {
            params: {
                all: true,
                search,
                Status,
                selectedItems,
                isAdmin: true,
                selectAll,
                Garantie: garantie,
                categ: category,
                Subcategory: subCategory_id,
                DLC: date_limite_de_consommation,
                pos: selectedPOS,
                Stock: selectedStatus == 2 ? "inStock" : selectedStatus == 3 ? "outOfStock" : "all"
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const applyTax = async ({
    taxId,
    productsIds,
    selectAll,
    search,
    status,
    garantie,
    category,
    subCategory_id,
    date_limite_de_consommation,
    selectedPOS,
    selectedStatus
}) => {
    console.log(search)
    try {
        const response = await apiClient.post('/products/apply-tax-multiple', {
            taxId,
            productsIds,
            selectAll,
            search,
            status,
            garantie,
            category,
            subCategory_id,
            date_limite_de_consommation,
            selectedPOS,
            selectedStatus,
        });
        return response.data;
    } catch (error) {
        console.error('Error applying tax:', error);
        throw error;
    }
};


export const applyCE = async ({
    CEId,
    productsIds,
    selectAll,
    search,
    status,
    garantie,
    category,
    subCategory_id,
    date_limite_de_consommation,
    selectedPOS,
    selectedStatus
}) => {
    try {
        const response = await apiClient.post('/products/apply-ce-multiple', {
            CEId,
            productsIds,
            selectAll,
            search,
            status,
            garantie,
            category,
            subCategory_id,
            date_limite_de_consommation,
            selectedPOS,
            selectedStatus
        });
        return response.data;
    } catch (error) {
        console.error('Error applying Category Expédition:', error);
        throw error;
    }
};

export const getStockPlus = async (page, perPage, search) => {
    try {
        const response = await apiClient.get('/frais_stock_plus', {
            params: {
                page,
                perPage,
                search,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Stock Plus Data:', error);
        throw error;
    }
};
export const CreateStockPlus = async (data) => {
    console.log(data)

    try {
        const response = await apiClient.post('/frais_stock_plus', data.data);
        return response.data;
    } catch (error) {
        console.error('Error creating Stock plus:', error);
        throw error;
    }
};

export const EditStockPlus = async (data) => {
    console.log(data);

    try {
        const response = await apiClient.post(`/frais_stock_plus/${data.data.id}`, {
            name: data.data.name,
            fee: data.data.fee,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating Stock Plus:', error);
        throw error;
    }
};

export const applyStock = async ({ StockId, productsIds, selectAll, search,
    status,
    garantie,
    category,
    subCategory_id,
    date_limite_de_consommation,
    selectedPOS,
    selectedStatus }) => {
    console.log(StockId)
    try {
        const response = await apiClient.post('/products/apply-stock-multiple', {
            StockId,
            productsIds,
            selectAll,
            search,
            status,
            garantie,
            category,
            subCategory_id,
            date_limite_de_consommation,
            selectedPOS,
            selectedStatus
        });
        return response.data;
    } catch (error) {
        console.error('Error applying Stock Plus:', error);
        throw error;
    }
};
export const getRoles = async () => {
    try {
        const response = await apiClient.get('/roles/all');
        return response.data;
    } catch (error) {
        console.error('Error fetching Roles:', error);
        throw error;
    }
};
export const PaginateRoles = async (page, perPage) => {
    try {
        const response = await apiClient.get('/roles', {
            params: {
                page,
                perPage,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Roles:', error);
        throw error;
    }
};

export const CreateRole = async (data) => {
    console.log(data)

    try {
        const response = await apiClient.post('/roles/create', data.data);
        return response.data;
    } catch (error) {
        console.error('Error creating Role:', error);
        throw error;
    }
};

export const updateRole = async (ID, data) => {
    console.log(data)
    try {
        const response = await apiClient.post(`/roles/${ID}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating Role:', error);
        throw error;
    }
};